import './assets/css/App.css'
import React, { useState, useMemo } from 'react'
import Button from '@mui/material/Button'
import Step1 from "./Steps/Step1"
import styled from '@emotion/styled'
import { ReactComponent as ArrowIcon } from "./assets/icons/arrow.svg";
import { useMediaQuery } from "@mui/material"
import { brands, equivalenceFactors } from './Database/Drugs'

const ButtonGroup = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: ${({ activeStep }) => (activeStep === 3 || activeStep < 1) ? 'end' : 'space-between'};
  align-items: center;
  margin-bottom: ${({ smallScreen }) => smallScreen ? '0' : '3rem'};
  padding-top: 5rem;
  @media (max-width: 768px) {
    width: auto;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 0 1.25rem;
    margin-top: 1.25rem;
    gap: 0.625rem;
  }
`;

function App() {

  const [activeStep, setActiveStep] = useState(0)
  const [selectedDrug, setSelectedDrug] = useState(null)
  const [drugs, setDrugs] = useState([])
  const smallScreen = useMediaQuery('(max-width:768px)')

  const handleNext = () => {

    if (selectedDrug) setDrugs([selectedDrug])

    let newStep = activeStep + 1

    setActiveStep(newStep)
    pushReplaceState(newStep)

  }

  const handleBack = () => {

    setDrugs([])

    let newStep = activeStep - 1

    setActiveStep(newStep)
    pushReplaceState(newStep)

  }

  const pushReplaceState = (step, replace = false) => {

    console.log(step)

    let newUrl

    if (step > 0) {

      const params = new URLSearchParams(window.location.search)
      params.set('step', step + 1)
      newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${params.toString()}`

    } else {

      newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`

    }

    if (replace) window.history.replaceState({ path: newUrl }, '', newUrl)
    else window.history.pushState({ path: newUrl }, '', newUrl)

  }

  window.onpopstate = () => {

    const urlParams = new URLSearchParams(window.location.search)
    
    let step = urlParams.get('step')

    if (step) step = parseInt(step) - 1
    else step = 0

    if (step === 0) setDrugs([])
    else if (selectedDrug) setDrugs([selectedDrug])

    setActiveStep(step)

  }

  const uniqueOpioids = useMemo(() => {

      return brands.filter(b => b.drugType === 'Opioder')

  }, [])

  const uniqueBenzodiazepiner = useMemo(() => {

      return brands.filter(b => b.drugType === 'Benzodiazepiner')

  }, [])

  return (

    <div className="Equivalence-App">

      {activeStep === 0 && (
        <>
          <div className="Equivalence-main-content">
            <Step1
              setActiveStep={setActiveStep}
              pushReplaceState={pushReplaceState}
              selectedDrug={selectedDrug}
              setSelectedDrug={setSelectedDrug}
              smallScreen={smallScreen}
              uniqueDrugs={uniqueBenzodiazepiner}
              drugType={'benzodiazepiner'}
              activeStep={activeStep}
              drugs={drugs}
              setDrugs={setDrugs}
              handleNext={handleNext}
              equivalenceFactors={equivalenceFactors}
            />
          </div>
          <div className="Equivalence-main-content">
            <Step1
              setActiveStep={setActiveStep}
              pushReplaceState={pushReplaceState}
              selectedDrug={selectedDrug}
              setSelectedDrug={setSelectedDrug}
              smallScreen={smallScreen}
              uniqueDrugs={uniqueOpioids}
              drugType={'opioder'}
              activeStep={activeStep}
              drugs={drugs}
              setDrugs={setDrugs}
              handleNext={handleNext}
              equivalenceFactors={equivalenceFactors}
            />
          </div>
        </>
      )}

      {activeStep === 1 && (
        <div className="Equivalence-main-content">
          <Step1
            setActiveStep={setActiveStep}
            pushReplaceState={pushReplaceState}
            selectedDrug={selectedDrug}
            setSelectedDrug={setSelectedDrug}
            smallScreen={smallScreen}
            uniqueDrugs={selectedDrug?.drugType.toLowerCase().includes('benzodiazepiner') ? uniqueBenzodiazepiner : uniqueOpioids}
            drugType={selectedDrug?.drugType.toLowerCase().includes('benzodiazepiner') ? 'benzodiazepiner' : 'opioder'}
            activeStep={activeStep}
            drugs={drugs}
            setDrugs={setDrugs}
            equivalenceFactors={equivalenceFactors}
          />
          <ButtonGroup className="MuiBox-root" activeStep={activeStep} smallScreen={smallScreen} style={{paddingTop: '50px'}}>
            <Button
              className="arrow-back"
              variant="contained"
              onClick={handleBack}
            >
              <ArrowIcon />
              Gå tilbake
            </Button>
          </ButtonGroup>
        </div>
      )}
    </div>
  )

}

export default App
