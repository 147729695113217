export const equivalenceFactors = [
    {
        "id": 1,
        "genericName":"Alprazolam",
        "drugType":"Benzodiazepiner",
        "equivalenceFactor":0.1
    },
    {
        "id": 2,
        "genericName":"Diazepam",
        "drugType":"Benzodiazepiner",
        "equivalenceFactor":1
    },
    {
        "id": 3,
        "genericName":"Flunitrazepam",
        "drugType":"Benzodiazepiner",
        "equivalenceFactor":0.05
    },
    {
        "id": 4,
        "genericName":"Klonazepam",
        "drugType":"Benzodiazepiner",
        "equivalenceFactor":0.1
    },
    {
        "id": 5,
        "genericName":"Nitrazepam",
        "drugType":"Benzodiazepiner",
        "equivalenceFactor":1
    },
    {
        "id": 6,
        "genericName":"Oksazepam ",
        "drugType":"Benzodiazepiner",
        "equivalenceFactor":3
    },
    {
        "id": 7,
        "genericName":"Zolpidem",
        "drugType":"Benzodiazepiner",
        "equivalenceFactor":1.5
    },
    {
        "id": 8,
        "genericName":"Zopiclon",
        "drugType":"Benzodiazepiner",
        "equivalenceFactor":1
    },
    {
        "id": 9,
        "genericName":"Morfin, oralt",
        "drugType":"Opioder",
        "equivalenceFactor":1
    },
    {
        "id": 10,
        "genericName":"Morfin, injeksjon",
        "drugType":"Opioder",
        "equivalenceFactor":3
    },
    {
        "id": 11,
        "genericName":"Oksykodon, oralt",
        "drugType":"Opioder",
        "equivalenceFactor":1.5
    },
    {
        "id": 12,
        "genericName":"Oksykodon, injeksjon",
        "drugType":"Opioder",
        "equivalenceFactor":3
    },
    {
        "id": 13,
        "genericName":"Buprenorfin, plaster",
        "drugType":"Opioder",
        "equivalenceFactor":2.22
    },
    {
        "id": 14,
        "genericName":"Buprenorfin, sublingvalt",
        "drugType":"Opioder",
        "equivalenceFactor":48
    },
    {
        "id": 15,
        "genericName":"Fentanyl, plaster",
        "drugType":"Opioder",
        "equivalenceFactor":2.4
    },
    {
        "id": 16,
        "genericName":"Fentanyl, sublingvalt",
        "drugType":"Opioder",
        "equivalenceFactor":0.2
    },
    {
        "id": 17,
        "genericName":"Hydromorfon, oralt",
        "drugType":"Opioder",
        "equivalenceFactor":5
    },
    {
        "id": 18,
        "genericName":"Hydromorfon, injeksjon",
        "drugType":"Opioder",
        "equivalenceFactor":15
    },
    {
        "id": 19,
        "genericName":"Ketobemidon, oralt",
        "drugType":"Opioder",
        "equivalenceFactor":1
    },
    {
        "id": 20,
        "genericName":"Ketobemidon, stikkpille",
        "drugType":"Opioder",
        "equivalenceFactor":0.3
    },
    {
        "id": 21,
        "genericName":"Petidin",
        "drugType":"Opioder",
        "equivalenceFactor":0.1
    },
    {
        "id": 22,
        "genericName":"Kodein",
        "drugType":"Opioder",
        "equivalenceFactor":0.1
    },
    {
        "id": 23,
        "genericName":"Tramadol",
        "drugType":"Opioder",
        "equivalenceFactor":0.15
    },
    {
        "id": 24,
        "genericName":"Tapentadol",
        "drugType":"Opioder",
        "equivalenceFactor":0.2
    },
    {
        "id": 25,
        "genericName":"Dihydrokodein",
        "drugType":"Opioder",
        "equivalenceFactor":0.05
    },
    {
        "id": 26,
        "genericName":"Metadon",
        "drugType":"Opioder",
        "maxDose": 89,
        "minDose": 0,
        "equivalenceFactor":5
    },
    {
        "id": 27,
        "genericName":"Metadon",
        "drugType":"Opioder",
        "minDose": 90,
        "maxDose": 299,
        "equivalenceFactor":6
    },
    {
        "id": 28,
        "genericName":"Metadon",
        "drugType":"Opioder",
        "minDose": 300,
        "maxDose": 599,
        "equivalenceFactor":8
    },
    {
        "id": 29,
        "genericName":"Metadon",
        "drugType":"Opioder",
        "minDose": 600,
        "maxDose": 999,
        "equivalenceFactor":10
    },
    {
        "id": 30,
        "genericName":"Metadon",
        "drugType":"Opioder",
        "minDose": 1000,
        "maxDose": 1000000,
        "equivalenceFactor":12
    },
];

export const brands = [
    {
        "id": 1,
        "genericName":"Alprazolam",
        "drugType":"Benzodiazepiner",
    },
    {
        "id": 2,
        "genericName":"Diazepam",
        "drugType":"Benzodiazepiner",
    },
    {
        "id": 3,
        "genericName":"Flunitrazepam",
        "drugType":"Benzodiazepiner",
    },
    {
        "id": 4,
        "genericName":"Klonazepam",
        "drugType":"Benzodiazepiner",
    },
    {
        "id": 5,
        "genericName":"Nitrazepam",
        "drugType":"Benzodiazepiner",
    },
    {
        "id": 6,
        "genericName":"Oksazepam ",
        "drugType":"Benzodiazepiner",
    },
    {
        "id": 7,
        "genericName":"Zolpidem",
        "drugType":"Benzodiazepiner",
    },
    {
        "id": 8,
        "genericName":"Zopiclon",
        "drugType":"Benzodiazepiner",
    },
    {
        "id": 9,
        "genericName":"Morfin, oralt",
        "drugType":"Opioder",
    },
    {
        "id": 10,
        "genericName":"Morfin, injeksjon",
        "drugType":"Opioder",
    },
    {
        "id": 11,
        "genericName":"Oksykodon, oralt",
        "drugType":"Opioder",
    },
    {
        "id": 12,
        "genericName":"Oksykodon, injeksjon",
        "drugType":"Opioder",
    },
    {
        "id": 13,
        "genericName":"Buprenorfin, plaster",
        "drugType":"Opioder",
    },
    {
        "id": 14,
        "genericName":"Buprenorfin, sublingvalt",
        "drugType":"Opioder",
    },
    {
        "id": 15,
        "genericName":"Fentanyl, plaster",
        "drugType":"Opioder",
    },
    {
        "id": 16,
        "genericName":"Fentanyl, sublingvalt",
        "drugType":"Opioder",
    },
    {
        "id": 17,
        "genericName":"Hydromorfon, oralt",
        "drugType":"Opioder",
    },
    {
        "id": 18,
        "genericName":"Hydromorfon, injeksjon",
        "drugType":"Opioder",
    },
    {
        "id": 19,
        "genericName":"Ketobemidon, oralt",
        "drugType":"Opioder",
    },
    {
        "id": 20,
        "genericName":"Ketobemidon, stikkpille",
        "drugType":"Opioder",
    },
    {
        "id": 21,
        "genericName":"Petidin",
        "drugType":"Opioder",
    },
    {
        "id": 22,
        "genericName":"Kodein",
        "drugType":"Opioder",
    },
    {
        "id": 23,
        "genericName":"Tramadol",
        "drugType":"Opioder",
    },
    {
        "id": 24,
        "genericName":"Tapentadol",
        "drugType":"Opioder",
    },
    {
        "id": 25,
        "genericName":"Dihydrokodein",
        "drugType":"Opioder",
    },
    {
        "id": 26,
        "genericName":"Metadon",
        "drugType":"Opioder",
    },
];

export const specialPlasterBrands = [
    "Buprenorfin, plaster", "Fentanyl, plaster"
];
export const specialOralBrands = [
    "Fentanyl, sublingvalt"
];