import React, { useEffect, useState } from 'react'
import {brands, specialPlasterBrands, specialOralBrands} from '../Database/Drugs'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Grid from '@mui/material/Grid'
import {Box, FormControl} from "@mui/material"
import Button from "@mui/material/Button"
import { ReactComponent as ArrowIcon } from "../assets/icons/arrow.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/close.svg";
import { ReactComponent as PlusIcon } from "../assets/icons/plus.svg";
import { ReactComponent as SearchIcon } from "../assets/icons/search.svg";
import styled from "@emotion/styled"

const ButtonGroup = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: ${({ activeStep }) => (activeStep === 3 || activeStep < 1) ? 'end' : 'end'};
  align-items: center;
  margin-bottom: ${({ smallScreen }) => smallScreen ? '0' : '0rem'};
  @media (max-width: 768px) {
    width: auto;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 0 1.25rem;
    margin-top: 1.25rem;
    gap: 0.625rem;
  }
`;

const explainerBenzo = 'Verktøyet er laget med utgangspunkt i "konverteringsverdier" basert på ratio mellom aktuelle DDD-verdier. Det er gjort tilpasninger basert på kliniske erfaringer. Verktøyet må derfor brukes med dette som utgangspunkt og på eget ansvar. Dosen som skal gis må alltid vurderes individuelt og konkret. De veiledende verdiene kan både være for høye og for lave. Lenke til offisielle DDD-verdier iht. WHO.'
const explainerOpioid = 'Opioider er en fellesbetegnelse på vanedannende, smertestillende legemidler med morfinlignende virkning. For å kunne sammenligne disse legemidlene på tvers av virkestoffer og form blir dosene omregnet til orale morfinekvivalenter (OMEQ). Det vil si mengden virkestoffet tilsvarer i morfin inntatt via munnen. Regn ut døgndose i mg av virkestoffet og fyll dette inn i "Døgndose". Denne kalkulatoren burde som hovedregel ikke benyttes til å konvertere opioid hos en pasient, da den ikke tar hensyn til individuelle faktorer.'

function Step1({
  setActiveStep,
  pushReplaceState,
  selectedDrug,
  setSelectedDrug,
  smallScreen,
  drugType,
  activeStep,
  drugs,
  setDrugs,
  handleNext,
  equivalenceFactors
}) {

  // Feedback Modal
  
  const [feedbackModal, setFeedbackModal] = useState(localStorage.getItem('feedbackModal'));

  setTimeout(() => {

    if (feedbackModal !== 'false') {

      setFeedbackModal(false)
      localStorage.setItem('feedbackModal', 'false')
      const popupModal = document.getElementById('popup-modal')
      if (popupModal) popupModal.classList.remove('display-none')

    }

  }, 60000);

  const isBenzo = selectedDrug?.drugType.toLowerCase().includes('benzodiazepiner')
  const isOpioid = selectedDrug?.drugType.toLowerCase().includes('opioder')

  useEffect(() => {

    if ((activeStep === 1) && (!isBenzo && !isOpioid)) {

      setActiveStep(0)
      pushReplaceState(0, true)
      setDrugs([])

    }

  })

  const handleDrugSelect = (event, value) => {

    if (value) {

      const enrichedDrug = {
        ...value,
        equivalenceDisabled: true,
        name: value.genericName,
      }

      setSelectedDrug(enrichedDrug)

    } else {

      setSelectedDrug(null)

    }

  }

  const handleAddDrug = (index, value) => {

    const existingDose = drugs.find((drug, i) => i === index)?.dose
    const equivalenceDisabled = drugs.find((drug, i) => i === index)?.equivalenceDisabled
    
    if (value) {

      const enrichedDrug = {
        ...value,
        equivalenceDisabled: equivalenceDisabled,
        dose: existingDose ?? null,
        name: value.genericName,
      }

      if (drugs[index]) {

        setDrugs(prev => prev.map((drug, i) => i === index ? enrichedDrug : drug))

      } else {

        setDrugs(prev => [...prev, enrichedDrug])

      }

    }

  }

  return (
    
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3} mt={0}>
        <Grid item xs={12} sx={{paddingTop: '0px !important'}} >
          <div className="Equivalence-step-header">{"Omregning av " + drugType}</div>
          {drugType === 'benzodiazepiner' && (
            <p className="Equivalence-explainer">{explainerBenzo}</p>
          )}
          {drugType === 'opioder' && (
            <p className="Equivalence-explainer">{explainerOpioid}</p>
          )}
        </Grid>
        {activeStep === 1 && isOpioid && !smallScreen && (
          <>
            <Grid item xs={6}>
              <InputLabel className="Equivalence-input-label" htmlFor="autocomplete">Søk etter virkestoff</InputLabel>
            </Grid>
            <Grid item xs={2}>
              <InputLabel className="Equivalence-input-label" htmlFor="autocomplete">Ekvivalens&shy;faktor</InputLabel>
            </Grid>
            <Grid item xs={2}>
              <InputLabel className="Equivalence-input-label" htmlFor="autocomplete">Døgndose</InputLabel>
            </Grid>
            <Grid item xs={1}>
              <InputLabel className="Equivalence-input-label" htmlFor="autocomplete">OMEQ</InputLabel>
            </Grid>
          </>
        )}
        {activeStep === 1 && isBenzo && !smallScreen && (
          <>
            <Grid item xs={3}>
              <InputLabel className="Equivalence-input-label" htmlFor="autocomplete">Søk etter virkestoff</InputLabel>
            </Grid>
            <Grid item xs={2}>
              <InputLabel className="Equivalence-input-label" htmlFor="autocomplete">Ekvivalens&shy;faktor</InputLabel>
            </Grid>
            <Grid item xs={2}>
              <InputLabel className="Equivalence-input-label" htmlFor="autocomplete">Døgndose (mg)</InputLabel>
            </Grid>
            <Grid item xs={2}>
              <InputLabel className="Equivalence-input-label" htmlFor="autocomplete">Tilsvarer Diazepam</InputLabel>
            </Grid>
            <Grid item xs={2}>
              <InputLabel className="Equivalence-input-label" htmlFor="autocomplete">Tilsvarer Oksazepam</InputLabel>
            </Grid>
          </>
        )}
        {drugs.length === 0 && (
          <>
            <Grid item xs={smallScreen ? 12 : 4}>
              <InputLabel className="Equivalence-input-label" htmlFor="autocomplete">Søk etter virkestoff</InputLabel>
              <Autocomplete
                id="autocomplete"
                disablePortal
                options={[...brands].sort((a, b) => a.genericName.localeCompare(b.genericName))} // Sorting alphabetically
                getOptionLabel={(option) => option.genericName}
                onChange={handleDrugSelect}
                value={selectedDrug?.drugType.toLowerCase().includes(drugType) ? selectedDrug : null}
                filterOptions={(options, { inputValue }) =>
                  options.filter((option) =>
                    option.genericName.toLowerCase().includes(inputValue.toLowerCase())
                  )
                }
                renderOption={(props, option) => {
                  if (option?.drugType.toLowerCase().includes(drugType)) {
                    return (
                      <li {...props}>
                        {option.genericName}
                      </li>
                    )
                  }
                }}
                renderInput={(params) => <TextField
                  {...params}
                  placeholder={drugType === 'opioder' ? 'Eksempel: Tramadol' : 'Eksempel: Zopiclon'}
                  sx={{
                    '& .MuiInputBase-root': {
                        paddingRight: '5px !important',
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <SearchIcon style={{ marginLeft: 8, color: 'rgba(0, 0, 0, 0.6)' }} />
                    ),
                  }}
                />}
              />
            </Grid>
            <Grid item xs={smallScreen ? 12 : 8} sx={{display:'flex', alignItems:'end', justifyContent:'end'}}>
              <ButtonGroup activeStep={activeStep} smallScreen={smallScreen} style={{margin: '0'}}>
                {drugType === 'benzodiazepiner' && (
                  <Button
                    className="arrow-forward"
                    variant="contained"
                    onClick={handleNext}
                    disabled={!isBenzo}
                  >
                    Gå videre
                    <ArrowIcon/>
                  </Button>
                )}
                {drugType === 'opioder' && (
                  <Button
                    className="arrow-forward"
                    variant="contained"
                    onClick={handleNext}
                    disabled={!isOpioid}
                  >
                    Gå videre
                    <ArrowIcon/>
                  </Button>
                )}
              </ButtonGroup>
            </Grid>
          </>
        )}
        {drugs.map((drug, index) => {
          return (
            <>
              <Grid item xs={smallScreen ? 12 : isOpioid ? 6 : 3}>
                {smallScreen && (
                  <InputLabel className="Equivalence-input-label" htmlFor="autocomplete" sx={{mb: 2}}>Søk etter virkestoff</InputLabel>
                )}
                <Autocomplete
                  id="autocomplete"
                  disablePortal
                  options={[...brands].sort((a, b) => a.genericName.localeCompare(b.genericName))} // Sorting alphabetically
                  getOptionLabel={(option) => option.genericName}
                  onChange={(e,value) => handleAddDrug(index,value)}
                  value={drug?.drugType.toLowerCase().includes(drugType) ? drug : null}
                  filterOptions={(options, { inputValue }) =>
                    options.filter((option) =>
                      option.genericName.toLowerCase().includes(inputValue.toLowerCase())
                    )
                  }
                  renderOption={(props, option) => {
                    if (option?.drugType.toLowerCase().includes(drugType)) {
                      return (
                        <li {...props}>
                          {option.genericName}
                        </li>
                      )
                    }
                  }}
                  renderInput={(params) => <TextField
                    {...params}
                    placeholder={drugType === 'opioder' ? 'Eksempel: Tramadol' : 'Eksempel: Zopiclon'}
                    sx={{
                      '& .MuiInputBase-root': {
                        paddingRight: '5px !important',
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <SearchIcon style={{ marginLeft: 8, color: 'rgba(0, 0, 0, 0.6)' }} />
                      ),
                    }}
                  />}
                />
              </Grid>
              {activeStep === 1 && isBenzo && (
                <>
                  <Grid item xs={smallScreen ? 6 : 2}>
                    {smallScreen && (
                      <InputLabel className="Equivalence-input-label">Ekvivalens&shy;faktor</InputLabel>
                    )}
                    <TextField
                      type="text"
                      fullWidth={true}
                      value={equivalenceFactors.find(ef => ef.genericName === drug.genericName)?.equivalenceFactor}
                      variant="outlined"
                      disabled={drug.equivalenceDisabled}
                    />
                  </Grid>
                  <Grid item xs={smallScreen ? 6 : 2}>
                    {smallScreen && (
                      <InputLabel className="Equivalence-input-label">Døgndose (mg)</InputLabel>
                    )}
                    <TextField
                      type="text"
                      fullWidth={true}
                      value={drug.dose ? Number(Number(drug.dose).toFixed(2)).toString() : null}
                      onChange={(e) =>
                        setDrugs((prev) =>
                          prev.map((drug, i) =>
                            i === index ? { ...drug, dose: e.target.value } : drug
                          )
                        )
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={smallScreen ? 6 : 2} sx={{alignContent: 'center'}}>
                    {smallScreen && (
                      <InputLabel className="Equivalence-input-label">Tilsvarer Diazepam</InputLabel>
                    )}
                    <FormControl sx={{display: 'flex', alignItems: 'flex-start'}}>
                      <span>{(() => {
                        const factor = equivalenceFactors.find(ef => ef.genericName === drug.genericName)?.equivalenceFactor;
                        return factor && drug.dose ? (drug.dose / factor).toFixed(2).toString() : "0.00";
                      })()}</span>
                    </FormControl>
                  </Grid>
                  <Grid item xs={smallScreen ? 6 : 2} sx={{alignContent: 'center'}}>
                    {smallScreen && (
                      <InputLabel className="Equivalence-input-label">Tilsvarer Oksazepam</InputLabel>
                    )}
                      <FormControl sx={{display: 'flex', alignItems: 'flex-start'}}>
                        <span>{(() => {
                          const factor = equivalenceFactors.find(ef => ef.genericName === drug.genericName)?.equivalenceFactor;
                          return factor && drug.dose ? ((drug.dose * 3) / factor).toFixed(2).toString() : "0.00";
                        })()}</span>
                      </FormControl>
                  </Grid>
                  {(index !== 0 || drugs.length > 1) && (
                    <Grid item xs={smallScreen ? 12 :1} sx={{textAlign: smallScreen ? 'end' : 'start', display:'flex', justifyContent: smallScreen ? 'end' : 'start'}}>
                      <FormControl sx={{display: 'flex', alignItems: 'center', flexDirection:'row'}}>
                        <CloseIcon
                          onClick={() => setDrugs((prev) => prev.filter((_, i) => i !== index))}
                          style={{cursor: 'pointer',height:'26px'}}
                        />
                        <p
                          className='Equivalence-decorated-link'
                          onClick={() => setDrugs((prev) => prev.filter((_, i) => i !== index))}>
                          Fjern
                        </p>
                      </FormControl>
                    </Grid>
                  )}
                </>
              )}
              {activeStep === 1 && isOpioid && (
                <>
                  <Grid item xs={smallScreen ? 6 : 2}>
                    {smallScreen && (
                      <InputLabel className="Equivalence-input-label">Ekvivalens&shy;faktor</InputLabel>
                    )}
                    <TextField
                      sx={{paddingBottom:0}}
                      type="text"
                      fullWidth={true}
                      value={(() => {
                        if (drug.genericName === "Metadon") {
                          return equivalenceFactors.find(ef =>
                            ef.genericName === "Metadon" &&
                            drug.dose >= (ef.minDose ?? -Infinity) &&
                            drug.dose <= (ef.maxDose ?? Infinity)
                          )?.equivalenceFactor || 0;
                        } else {
                          return equivalenceFactors.find(ef => ef.genericName === drug.genericName)?.equivalenceFactor || 0;
                        }
                      })()}
                      variant="outlined"
                      disabled={drug.equivalenceDisabled}
                    />
                  </Grid>
                  <Grid item xs={smallScreen ? 6 : 2}>
                    {smallScreen && (
                      <InputLabel className="Equivalence-input-label">{specialPlasterBrands.some((sb, index) => drug.genericName === sb) ? 'Plasterstyrken (μg)' : specialOralBrands.some((sb, index) => drug.genericName === sb) ? 'Oralstyrken (μg)' : 'Døgndose (mg)'}</InputLabel>
                    )}
                    {!smallScreen && (
                      <div style={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                        <TextField
                          type="text"
                          fullWidth={true}
                          value={drug.dose ? Number(Number(drug.dose).toFixed(2)).toString() : null}
                          onChange={(e) =>
                            setDrugs((prev) =>
                              prev.map((drug, i) =>
                                i === index ? {...drug, dose: e.target.value} : drug
                              )
                            )
                          }
                          variant="outlined"
                        />
                        <span style={{marginLeft:'0.5rem'}}>
                          {specialPlasterBrands.some((sb, index) => drug.genericName === sb) ? 'μg' : specialOralBrands.some((sb, index) => drug.genericName === sb) ? 'μg' : 'mg'}
                        </span>
                        <small className="field-description">
                          {specialPlasterBrands.some((sb, index) => drug.genericName === sb) ? 'Plasterstyrken (μg)' : specialOralBrands.some((sb, index) => drug.genericName === sb) ? 'Oralstyrken (μg)' : ''}
                        </small>
                      </div>
                    )}
                    {smallScreen && (
                      <TextField
                        type="text"
                        fullWidth={true}
                        value={drug.dose ? Number(Number(drug.dose).toFixed(2)).toString() : null}
                        onChange={(e) =>
                          setDrugs((prev) =>
                            prev.map((drug, i) =>
                              i === index ? { ...drug, dose: e.target.value } : drug
                            )
                          )
                        }
                        variant="outlined"
                      />
                    )}
                  </Grid>
                  <Grid item xs={smallScreen ? 6 : 1} sx={{alignContent: 'center'}}>
                    {smallScreen && (
                      <InputLabel className="Equivalence-input-label" sx={{ mb: 2, textAlign:smallScreen ? 'start' : 'end'}}>OMEQ</InputLabel>
                    )}
                    <FormControl sx={{display:'flex', alignItems:smallScreen ? 'start' : 'flex-end'}}>
                      <span>{(() => {
                        if (drug.genericName === "Metadon") {
                          // Find the correct equivalence factor based on the dose range
                          const factor = equivalenceFactors.find(ef =>
                            ef.genericName === "Metadon" &&
                            drug.dose >= (ef.minDose ?? -Infinity) &&
                            drug.dose <= (ef.maxDose ?? Infinity)
                          )?.equivalenceFactor;
                          return factor && drug.dose
                            ? (drug.dose * factor).toFixed(2).toString()
                            : "0.00"; // Fallback value if factor or dose is invalid
                        } else {
                          // Default logic for other drugs
                          const factor = equivalenceFactors.find(ef => ef.genericName === drug.genericName)?.equivalenceFactor;
                          return factor && drug.dose
                            ? (drug.dose * factor).toFixed(2).toString()
                            : "0.00"; // Fallback value if factor or dose is invalid
                        }
                      })()}</span>
                    </FormControl>
                  </Grid>
                  {(index !== 0 || drugs.length > 1) && (
                    <Grid item xs={smallScreen ? 12 :1} sx={{textAlign: smallScreen ? 'end' : 'start', display:'flex', justifyContent: 'end'}}>
                      <FormControl sx={{display: 'flex', alignItems: 'center', flexDirection:'row'}}>
                        <CloseIcon
                          onClick={() => setDrugs((prev) => prev.filter((_, i) => i !== index))}
                          style={{
                              cursor: 'pointer',
                          }}
                        />
                        <p
                          className='Equivalence-decorated-link'
                          onClick={() => setDrugs((prev) => prev.filter((_, i) => i !== index))}>
                          Fjern
                        </p>
                      </FormControl>
                    </Grid>
                  )}
                </>
              )}
            </>
          )
        })}
        {activeStep === 1 && (
          <Grid item xs={11} sx={{
            textAlign: 'left',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            <div
              onClick={() => setDrugs(prev => [...prev, {
              genericName: '',
              drugType: '',
              equivalenceDisabled: true
              }])}
              style={{display: 'flex', alignItems: 'center'}}>
              <PlusIcon style={{cursor:'pointer',height:'26px'}}/>
              <span className="Equivalence-decorated-link">Legg til preparat</span>
            </div>
            {isOpioid && (
              <div>
                <strong>Sum OMEQ</strong>
                <p style={{textAlign: 'end'}}>
                {(() => {
                      const totalOMEQ = drugs.reduce((total, drug) => {
                        const equivalenceFactor = (() => {
                          if (drug.genericName === "Metadon") {
                            return equivalenceFactors.find(ef =>
                              ef.genericName === "Metadon" &&
                              drug.dose >= (ef.minDose ?? -Infinity) &&
                              drug.dose <= (ef.maxDose ?? Infinity)
                            )?.equivalenceFactor || 0;
                          } else {
                            return equivalenceFactors.find(ef => ef.genericName === drug.genericName)?.equivalenceFactor || 0;
                          }
                        })();
                        const dose = drug.dose ?? 0;
                        return total + (dose * equivalenceFactor);
                      }, 0);
                      return totalOMEQ.toFixed(2).toString();
                    })()}
                </p>
              </div>

            )}
          </Grid>
        )}
      </Grid>
    </Box>
  )

}

export default Step1;
